"use strict";
import { MARKETS_SLUGS, ROUTES } from "@/app/utils/constants";
export default defineNuxtRouteMiddleware((to) => {
  if (!ROUTES.spotMarketRouteNames.includes(to.name)) {
    return;
  }
  const pairs = MARKETS_SLUGS.spotMarketRedirectsSlugsPairs;
  const sources = Object.keys(pairs);
  for (const source of sources) {
    const destination = pairs[source];
    if (to.params.spot === source) {
      return navigateTo({
        name: "spot-spot",
        params: {
          spot: destination
        }
      });
    }
  }
});
